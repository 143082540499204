// reducer.ts

import { IUser } from "../types/types";
import { LOGIN, LOGOUT, LoginAction, LogoutAction } from "./actions";

export interface IAuthState {
  token: string | null;
  user: IUser | null;
  isAuthenticated: boolean;
}

const initialState: IAuthState = {
  token: null,
  user: null,
  isAuthenticated: false,
};

export const authReducer = (
  state = initialState,
  action: LoginAction | LogoutAction
): IAuthState => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: !!action.payload.token,
      };
    case LOGOUT:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
