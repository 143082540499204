import React from "react";
import { globalSpacing } from "../../../src/theme";
import { AppBar, Box, Typography } from "@mui/material";
import styles from "./Footer.module.css";
import generateConfig from "../../../util/config";

const config = generateConfig();

export default function Footer() {
  return (
    <AppBar position="relative" component={"footer"}>
      <Box px={globalSpacing}>
        <Box className={styles.footerContainer}>
          <Typography variant="h3">{config.logoName || ""}</Typography>
          <Typography>
            Copyright &copy; {config.siteName || ""}&nbsp;
            {new Date().getFullYear()}.
          </Typography>
        </Box>
      </Box>
    </AppBar>
  );
}
