export default function generateConfig(): Config {
  return {
    env: process.env.NODE_ENV,
    sentryDsn: process.env.NEXT_PUBLIC_SENTRY_DSN || "",
    appShaVersion: process.env.NEXT_PUBLIC_GIT_COMMIT_REF || "",
    GAMeasurementID: process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || "",
    mongoDBUri: process.env.MONGODB_URI || "",
    logoName: process.env.LOGO_NAME || "",
    siteName: process.env.SITE_NAME || "",
    siteUri: process.env.SITE_URL || "",
    googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || "",
    googleClientSecrets: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_SECRETS || "",
    logging: process.env.NEXT_PUBLIC_LOGGING || "",

    jwtSecret: process.env.JWT_SECRET || "",
    accessTokenSecret: process.env.ACCESS_TOKEN_SECRET || "",
    refreshTokenSecret: process.env.REFRESH_TOKEN_SECRET || "",

    geminiApiKey: process.env.GEMINI_API_KEY || "",
  };
}

type Config = {
  env: string;
  sentryDsn: string;
  appShaVersion: string;
  GAMeasurementID: string;
  mongoDBUri: string;
  logoName: string;
  siteName: string;
  siteUri: string;
  googleClientId: string;
  googleClientSecrets: string;
  logging: string;

  jwtSecret: string;
  accessTokenSecret: string;
  refreshTokenSecret: string;

  geminiApiKey: string;
};
