import generateConfig from "../../util/config";
import { useRouter } from "next/router";
import React, { ReactNode } from "react";

interface MetaCompProps {
  WrapperComp: ({ children }: { children: ReactNode }) => JSX.Element;
  title: string;
  description: string;
  keywords?: string;
  img?: string;
  content?: string;
  url?: string;
}

const config = generateConfig();

export default function MetaComp({
  WrapperComp,
  title,
  description,
  keywords,
  img,
  content,
  url,
}: MetaCompProps) {
  const { asPath } = useRouter();
  return (
    <WrapperComp>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta name="description" content={description} />
      <meta
        property="og:url"
        content={url ? url : `${config.siteUri}${asPath}`}
      />
      <meta property="og:type" content={content ? content : "website"} />
      <meta property="og:description" content={description} />
      {img && <meta property="og:image" content={img} />}
      <meta
        property="twitter:url"
        content={url ? url : `${config.siteUri}${asPath}`}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {img && <meta name="twitter:image" content={img} />}
      {keywords ? <meta name="keywords" content={keywords} /> : ""}
    </WrapperComp>
  );
}
