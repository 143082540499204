// store.ts

import { combineReducers, createStore, Store } from "redux";
import { IAuthState, authReducer } from "./reducer";
import { conversationReducer } from "./reducers/conversation";
import { IConversation } from "./types/conversationTypes";

export interface IRootState {
  auth: IAuthState;
  conversation: IConversation[];
}

const rootReducer = combineReducers<IRootState>({
  auth: authReducer,
  conversation: conversationReducer,
});

export const store: Store<IRootState> = createStore(rootReducer);
