// actions.ts

import { IUser } from "../types/types";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

export interface LoginAction {
  type: typeof LOGIN;
  payload: {
    token: string;
    user: IUser;
  };
}

export interface LogoutAction {
  type: typeof LOGOUT;
}

export const login = (token: string, user: IUser): LoginAction => ({
  type: LOGIN,
  payload: {
    token,
    user,
  },
});

export const logout = (): LogoutAction => ({
  type: LOGOUT,
});
