import React from "react";
import { Box, AppBar } from "@mui/material";
import Header from "./Header";
import Footer from "./Footer";
import { SelectThemeType } from "../../types/types";
import { motion, AnimatePresence } from "framer-motion";
import { useRouter } from "next/router";

interface PrimaryLayoutProps {
  children: React.ReactElement;
  toggleTheme?: React.MouseEventHandler<HTMLButtonElement>;
  selectedTheme: SelectThemeType;
}

export default function PrimaryLayout({
  children,
  toggleTheme,
  selectedTheme,
}: PrimaryLayoutProps) {
  const { asPath } = useRouter();
  return (
    <AnimatePresence mode="wait">
      <Box
        key={asPath}
        component={motion.div}
        initial="initialState"
        animate="animateState"
        exit="exitState"
        transition={{
          duration: 0.5,
        }}
        variants={{
          initialState: {
            opacity: 0,
            clipPath: "polygon(50% 0, 50% 0, 50% 100%, 50% 100%)",
          },
          animateState: {
            opacity: 1,
            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0 100%)",
          },
          exitState: {
            clipPath: "polygon(50% 0, 50% 0, 50% 100%, 50% 100%)",
          },
        }}
      >
        <AppBar position="relative">
          <Header toggleTheme={toggleTheme} selectedTheme={selectedTheme} />
        </AppBar>
        <Box sx={{ minHeight: "calc(100vh - 190px)" }}>{children}</Box>
        <Footer />
      </Box>
    </AnimatePresence>
  );
}
