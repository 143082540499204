export const ADD_USER_CONVERSATION = "ADD_USER_CONVERSATION";
export const ADD_BOT_CONVERSATION = "ADD_BOT_CONVERSATION";

export interface userAction {
  type: typeof ADD_USER_CONVERSATION;
  payload: {
    prompt: string;
  };
}

export interface botAction {
  type: typeof ADD_BOT_CONVERSATION;
  payload: {
    message: string;
  };
}

export const addUserConversation = (prompt: string): userAction => ({
  type: ADD_USER_CONVERSATION,
  payload: {
    prompt,
  },
});

export const addBotConversation = (message: string): botAction => ({
  type: ADD_BOT_CONVERSATION,
  payload: {
    message,
  },
});
