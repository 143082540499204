import Cookies from "js-cookie";

// Cache the date object
const date = new Date();

/**
 * A function that returns the ISO 3166-1 alpha-2 code of the current user's country, based on their browser's language settings.
 * @returns The ISO 3166-1 alpha-2 code of the current user's country, or an empty string if the code couldn't be determined.
 * @example
 * const currentCountryCode = getCurrentCountry(); // Returns "US" for a user in the United States with their browser language set to "en-US"
 */
export function getTimeStamp(): string {
  const options = { timeZone: "Asia/Kolkata" };
  const formattedDate = date.toLocaleString("en-IN", options);
  return formattedDate;
}

/**
 * Returns the current country based on the device's language.
 * @returns {string} - The current country.
 */
export function getCurrentCountry(): string {
  const language = navigator.language || navigator.languages[0];
  const countryCode = language.split("-")[1];
  return countryCode;
}

interface UserImgLoaderProps {
  src: string;
}
/**
 * A function that returns the input `src` string, used as a custom image loader for user images.
 * @param props - An object containing the `src` property, which is the URL of the image to load.
 * @returns The `src` property value passed in as an argument.
 * @example
 * const imageSrc = "https://example.com/user123.jpg";
 * const loaderProps = { src: imageSrc };
 * const imageUrl = userImgLoader(loaderProps); // Returns "https://example.com/user123.jpg"
 */
export const userImgLoader = ({ src }: UserImgLoaderProps): string => {
  return src;
};

/**
 * Sets the "authToken" cookie with the given token value.
 * @param token - The token value to set in the cookie.
 * @example
 * const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExODQzMDk2NjQ3Mzg1NDU4NTc0MyIsImVtYWlsIjoia3J1c2huYXNyOTZAZ21haWwuY29tIiwiaWF0IjoxNjgzMzE2NTE3LCJleHAiOjE2ODM0MDI5MTd9.9M7HsA5EVFywfwiuynCKnl0lnPTnpcERGHI-RUj8xSA";
 * setTokenCookie(token); // Sets the "authToken" cookie with the value "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjExODQzMDk2NjQ3Mzg1NDU4NTc0MyIsImVtYWlsIjoia3J1c2huYXNyOTZAZ21haWwuY29tIiwiaWF0IjoxNjgzMzE2NTE3LCJleHAiOjE2ODM0MDI5MTd9.9M7HsA5EVFywfwiuynCKnl0lnPTnpcERGHI-RUj8xSA"
 */
export const setTokenCookie = (token: string) => {
  Cookies.set("authToken", token, { expires: 30, sameSite: "strict" });
};

/**
 * Returns the value of the "authToken" cookie, or undefined if the cookie doesn't exist.
 * @returns The value of the "authToken" cookie, or undefined if the cookie doesn't exist.
 * @example
 * const token = getTokenCookie();
 * if (token) {
 *   // Do something with the token value
 * } else {
 *   // Handle the case where the token cookie doesn't exist
 * }
 */
export const getTokenCookie = () => {
  Cookies.get("authToken");
};

/**
 * Removes the "authToken" cookie.
 * @example
 * removeTokenCookie(); // Removes the "authToken" cookie
 */
export const removeTokenCookie = () => {
  Cookies.remove("authToken");
};
