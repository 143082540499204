import React from "react";
import { List, ListItem, Typography } from "@mui/material";
import { menuItem } from "../Header/menuItem";
import SideMenuList from "./SideMenuList";
import useAuthProvider from "../../../store/AuthProvider";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import styles from "./SideMenu.module.css";

interface SideMenuProps {
  isMobile: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SideMenu({ setIsOpen, isMobile }: SideMenuProps) {
  const auth = useAuthProvider();

  const logoutHandler = () => {
    auth.logout();
    setIsOpen(false);
  };

  return (
    <List
      style={isMobile ? { minWidth: "60vw" } : { minWidth: "300px" }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
      sx={{
        position: "absolute",
        background: "#fff",
        paddingTop: "24px",
        height: "100vh",
        color: (theme) => theme.palette.primary.main,
        right: 0,
        zIndex: 10,
        top: 0,
      }}
    >
      <CloseRoundedIcon
        color="primary"
        onClick={() => setIsOpen(false)}
        style={{
          cursor: "pointer",
          fontSize: "50px",
          width: "50px",
          height: "50px",
          float: "right",
          marginRight: "10px",
        }}
      />
      {menuItem
        .filter((item) => {
          if (auth.isAuthenticated) {
            return !item.hideOnAuthenticate || item.isResctricted;
          }
          return !item.isResctricted;
        })
        .map((item) => (
          <SideMenuList
            key={item.path}
            label={item.label}
            path={item.path}
            onClose={setIsOpen}
          />
        ))}
      {auth.isAuthenticated ? (
        <ListItem onClick={logoutHandler}>
          <Typography className={styles.text} variant="h4">
            Log out
          </Typography>
        </ListItem>
      ) : null}
    </List>
  );
}
