import "../styles/globals.css";
import * as React from "react";
import Head from "next/head";
import { AppProps } from "next/app";
import { EmotionCache } from "@emotion/react";
import Script from "next/script";
import AppComp from "../components/utilComp/AppComp";
import MetaComp from "../components/utilComp/MetaComp";
import generateConfig from "../util/config";

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const config = generateConfig();

export default function MyApp(props: MyAppProps) {
  const { Component, emotionCache, pageProps } = props;
  const { metaTags } = pageProps;

  return (
    <>
      {metaTags && (
        <MetaComp
          title={metaTags.title}
          WrapperComp={Head}
          description={metaTags.description}
        />
      )}
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${config.GAMeasurementID}`}
      />
      <Script
        id="google-analytics"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${config.GAMeasurementID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <AppComp
        pageProps={pageProps}
        emotionCache={emotionCache}
        Component={Component}
      />
    </>
  );
}
