import React, { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  useMediaQuery,
  List,
  ListItem,
  Button,
  Backdrop,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import Typography from "@mui/material/Typography";
import { globalSpacing } from "../../../src/theme";
import styles from "./Header.module.css";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import SideMenu from "../SideMenu";
import { menuItem } from "./menuItem";
import generateConfig from "../../../util/config";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import LightModeIcon from "@mui/icons-material/LightMode";
import { SelectThemeType } from "../../../types/types";
import useAuthProvider from "../../../store/AuthProvider";
import useMenuAnimation from "../../../hooks/useMenuAnimation";

const config = generateConfig();

const style = {
  logoText: { fontSize: "28px", fontWeight: "700", lineHeight: "1.2" },
};

export interface HeaderProps {
  toggleTheme?: React.MouseEventHandler<HTMLButtonElement>;
  selectedTheme: SelectThemeType;
}

export default function Header({ toggleTheme, selectedTheme }: HeaderProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const isLightMode = selectedTheme.type === "light";

  const scope = useMenuAnimation(isOpen);

  const auth = useAuthProvider();
  const router = useRouter();

  const ignoredRoutes =
    router.pathname === "/signup" ||
    router.pathname === "/signin" ||
    router.pathname === "/login";

  useEffect(() => {
    if (!ignoredRoutes) {
      sessionStorage && sessionStorage.setItem("prevPath", router.asPath);
    }
  }, [router.asPath]);

  return (
    <Box className={styles.headerContainer} px={globalSpacing}>
      <Link href={"/"} passHref>
        <Typography variant="h2" sx={style.logoText}>
          {config.logoName || ""}
        </Typography>
      </Link>
      <List className={styles.navLinkContainer}>
        <Button onClick={toggleTheme}>
          {isLightMode ? (
            <Brightness4Icon style={{ color: "#fff" }} />
          ) : (
            <LightModeIcon style={{ color: "#fff" }} />
          )}
        </Button>
        {matches ? (
          <>
            {menuItem
              .filter((item) => {
                if (auth.isAuthenticated) {
                  return !item.hideOnAuthenticate || item.isResctricted;
                }
                return !item.isResctricted;
              })
              .map((item) => (
                <ListItem key={item.path}>
                  <Link href={item.path} passHref>
                    <Typography sx={{ width: "max-content" }}>
                      {item.label}
                    </Typography>
                    {item.path === router.pathname && (
                      <Box
                        sx={{
                          width: "100%",
                          height: "2px",
                          borderRadius: "2px",
                          background: "#fff",
                        }}
                      />
                    )}
                  </Link>
                </ListItem>
              ))}
          </>
        ) : null}
        {auth.isAuthenticated && matches ? (
          <ListItem sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "relative",
                borderRadius: "50%",
                overflow: "hidden",
              }}
            >
              <Image
                src={auth.user?.profile || ""}
                width={20}
                height={20}
                alt="profile"
              />
            </Box>
            <Typography noWrap>{auth.user?.name || ""}</Typography>
          </ListItem>
        ) : null}
        <ListItem>
          <MenuRoundedIcon
            fontSize="medium"
            width={36}
            height={36}
            sx={{ cursor: "pointer" }}
            onClick={() => setIsOpen((prev) => !prev)}
          />
        </ListItem>
        <Box
          ref={scope}
          sx={{
            zIndex: 1000,
          }}
        >
          <Backdrop
            open={isOpen}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <SideMenu setIsOpen={setIsOpen} isMobile={!matches} />
          </Backdrop>
        </Box>
      </List>
    </Box>
  );
}
