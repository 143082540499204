import { MenuItemType } from "../../../types/types";

export const menuItem: MenuItemType[] = [
  {
    label: "Home",
    path: "/",
    isResctricted: false,
    hideOnAuthenticate: false,
  },
  {
    label: "Contact",
    path: "/contact",
    isResctricted: false,
    hideOnAuthenticate: false,
  },
  {
    label: "Chat Bot",
    path: "/chatbot",
    isResctricted: true,
    hideOnAuthenticate: false,
  },
  {
    label: "Sign in",
    path: "/signin",
    isResctricted: false,
    hideOnAuthenticate: true,
  },
];
