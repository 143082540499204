import React from "react";
import { NextPageContext, NextComponentType } from "next/types";
import { CacheProvider, EmotionCache } from "@emotion/react";
import { Hydrate, QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../../util/queryClient";
import { ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import theme from "../../src/theme";
import darkTheme from "../../src/darkTheme";
import createEmotionCache from "../../src/createEmotionCache";
import CssBaseline from "@mui/material/CssBaseline";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import PrimaryLayout from "./PrimaryLayout";
import { SelectThemeType } from "../../types/types";
import { GoogleOAuthProvider } from "@react-oauth/google";
import generateConfig from "../../util/config";
import { Provider } from "react-redux";
import { store } from "../../store/store";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const config = generateConfig();

interface AppCompPros {
  emotionCache?: EmotionCache;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Component: NextComponentType<NextPageContext, any, any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any;
}

export default function AppComp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: AppCompPros) {
  const [selectedTheme, setSelectedTheme] = React.useState<SelectThemeType>({
    type: "light",
  });

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const customTheme = React.useMemo(() => {
    switch (selectedTheme.type) {
      case "light":
        return theme;
      case "dark":
        return darkTheme;
      default:
        return theme;
    }
  }, [selectedTheme]);

  const toggleTheme: React.MouseEventHandler<HTMLButtonElement> = () => {
    const desiredTheme = selectedTheme.type === "light" ? "dark" : "light";
    setSelectedTheme({ type: desiredTheme });
  };

  React.useEffect(() => {
    setSelectedTheme({ type: prefersDarkMode ? "dark" : "light" });
  }, [prefersDarkMode]);

  return (
    <CacheProvider value={emotionCache}>
      <ThemeProvider theme={customTheme}>
        <Provider store={store}>
          <GoogleOAuthProvider clientId={config.googleClientId}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <QueryClientProvider client={queryClient}>
              <Hydrate state={pageProps?.dehydratedState}>
                <PrimaryLayout
                  toggleTheme={toggleTheme}
                  selectedTheme={selectedTheme}
                >
                  <Component
                    {...pageProps}
                    toggleTheme={toggleTheme}
                    selectedTheme={selectedTheme}
                  />
                </PrimaryLayout>
                <ReactQueryDevtools initialIsOpen={false} />
              </Hydrate>
            </QueryClientProvider>
          </GoogleOAuthProvider>
        </Provider>
      </ThemeProvider>
    </CacheProvider>
  );
}
