import { Poppins, Open_Sans } from "next/font/google";
import { createTheme, PaletteColor, ThemeOptions } from "@mui/material/styles";

const { breakpoints, palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string): PaletteColor =>
  augmentColor({ color: { main: mainColor } });

export const poppins = Poppins({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["Open Sans", "Arial", "sans-serif"],
});

export const openSans = Open_Sans({
  weight: ["300", "400", "500", "700"],
  subsets: ["latin"],
  display: "swap",
  fallback: ["Poppins", "Arial", "sans-serif"],
});

//Colors
const primary = createColor("#000");
const primaryVariant = createColor("#3700b3");
const secondary = createColor("#03dac6");
const secondaryVariant = createColor("#03dac6");
const background = createColor("#000000");
const surface = createColor("#000000");
const error = createColor("#cf6679");
const onPrimary = createColor("#000000");
const onSecondary = createColor("#000000");
const onBackground = createColor("#ffffff");
const onSurface = createColor("#ffffff");
const onError = createColor("#000000");
const nProgress = createColor("#fd0640");

const themeOptions = {
  palette: {
    primary,
    primaryVariant,
    secondary,
    secondaryVariant,
    background,
    surface,
    error,
    onPrimary,
    onSecondary,
    onBackground,
    onSurface,
    onError,
    nProgress,
  },
  typography: {
    fontFamily: poppins.style.fontFamily,
    h1: {
      fontSize: "24px",
      lineHeight: "30px",
      fontWeight: 700,
      [breakpoints.down("md")]: {
        fontSize: "20px",
        lineHeight: "26px",
      },
    },
    h2: {
      fontSize: "22px",
      lineHeight: "28px",
      fontWeight: 600,
      [breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "22px",
      },
    },
    h3: {
      fontSize: "20px",
      lineHeight: "26px",
      fontWeight: 500,
      [breakpoints.down("md")]: {
        fontSize: "18px",
        lineHeight: "24px",
      },
    },
    h4: {
      fontSize: "18px",
      lineHeight: "24px",
      fontWeight: 500,
      [breakpoints.down("md")]: {
        fontSize: "16px",
        lineHeight: "22px",
      },
    },
    h5: {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
    h6: {
      fontSize: "16px",
      lineHeight: "22px",
      fontWeight: 400,
      [breakpoints.down("md")]: {
        fontSize: "14px",
        lineHeight: "20px",
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
} as ThemeOptions;

// Create a theme instance.
const darkTheme = createTheme(themeOptions);

export default darkTheme;
