import { useDispatch, useSelector } from "react-redux";
import { login as userLogin, logout as userLogout } from "./actions";
import { IUser } from "../types/types";
import { IAuthState } from "./reducer";
import { IRootState } from "./store";
import { removeTokenCookie, setTokenCookie } from "../util/util";

export default function useAuthProvider() {
  const dispatch = useDispatch();

  const { isAuthenticated, token, user } = useSelector<IRootState, IAuthState>(
    (state) => state.auth
  );

  const login = (token: string, user: IUser) => {
    dispatch(userLogin(token, user));
    setTokenCookie(token || "");
  };

  const logout = () => {
    dispatch(userLogout());
    removeTokenCookie();
  };

  return { isAuthenticated, token, user, login, logout };
}
