import {
  ADD_BOT_CONVERSATION,
  ADD_USER_CONVERSATION,
  botAction,
  userAction,
} from "../actions/conversationAction";
import type { IConversation } from "../types/conversationTypes";

const initialState: IConversation[] = [];

export const conversationReducer = (
  state = initialState,
  action: userAction | botAction
): IConversation[] => {
  switch (action.type) {
    case ADD_USER_CONVERSATION:
      const userConversation: IConversation = {
        idx: state.length,
        message: action.payload.prompt,
        author: "User",
        timeStamp: new Date().toLocaleString(),
      };

      return [...state, userConversation];

    case ADD_BOT_CONVERSATION:
      const botConversation: IConversation = {
        idx: state.length,
        message: action.payload.message,
        author: "Bot",
        timeStamp: new Date().toLocaleString(),
      };

      return [...state, botConversation];

    default:
      return state;
  }
};
