import React from "react";
import { Box, ListItem, Typography } from "@mui/material";
import Link from "next/link";
import styles from "./SideMenu.module.css";
import { useRouter } from "next/router";

interface SideMenuListProps {
  path: string;
  label: string;
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function SideMenuList({
  path,
  label,
  onClose,
}: SideMenuListProps) {
  const pathname = useRouter().pathname;
  const isSelected = pathname === path;

  return (
    <Link href={path} passHref onClick={() => onClose(false)}>
      <ListItem>
        <Box>
          <Typography variant="h4" className={styles.text}>
            {label}
          </Typography>
          {isSelected && (
            <Box
              sx={{
                width: "100%",
                marginTop: "2px",
                borderRadius: "3px",
                height: "5px",
                background: (theme) => theme.palette.primary.main,
              }}
            />
          )}
        </Box>
      </ListItem>
    </Link>
  );
}
